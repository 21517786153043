<template>
  <div class="text-white bg-black">
    <div class="sm:container relative sm:pt-14 sm:pb-28 pt-14 pb-14 sm:0 px-4 relative">
      <slot name="img-circle"></slot>
      <img
        class="absolute left-0 bottom-0 hidden sm:block"
        src="@/assets/img/main/main-strip.svg"
        alt="">
      <img
        class="absolute left-0 top-[-52%] sm:hidden block"
        src="@/assets/img/main/mobile-strip.svg"
        alt="">
      <slot name="mobile-img-circle"></slot>
      <div class="relative z-20">
        <h1 class="xl:text-4xl lg:text-3xl text-32">{{title}}</h1>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    title: String,
  },
};
</script>
