<template>
  <div v-if="reviews" class="lg:mb-[192px] pb-24">
    <div class="container" v-if="!mobileSize">
      <h2 class="sm:text-3xl text-24">Отзывы</h2>
      <div class="flex mt-10 border-[1px] border-black hidden lg:block">
        <div class="w-full relative">
          <div v-if="!mobileSize">
            <swiper
              @swiper="onSwiper"
              :slides-per-view="1"
              :space-between="50"
              :autoHeight="true"
              :autoplay="{
                delay: 5000,
                disableOnInteraction: true,
              }"
              @slideChange="onSlideChange"
              :navigation="{
                  prevEl: prev,
                  nextEl: next,
                }"
              :modules="modules"
              :loop="true"
              @mouseenter="mouseEnter"
              @mouseleave="mouseLeave"
            >
              <swiper-slide v-for="review in reviewedText" :key="review.slug">
                <div class="flex">
                  <div class="w-2/3 relative">
                    <div class="xl:pr-20 pr-10 pt-10 pb-20 pl-10">
                      <div class="parag-inline">
                        <p v-if="!isShowContent" >{{review.short}}</p>
                        <p v-if="isShowContent" v-html="review.review"></p>
                      </div>
                    </div>
                  </div>
                  <div class="w-1/3 xl:pr-20 pr-10 pt-10 pb-20 pl-10
                  border-l-[1px] border-l-black relative">
                    <img
                      class="absolute -right-[1px] bottom-0"
                      src="@/assets/img/review/review-strip.svg"
                      alt=""
                    />
                    <div class="h-full">
                      <div class="flex flex-col justify-between h-full">
                        <div>
                          <h3 class="text-xl mb-7">Заказчик</h3>
                          <p class="mb-7">
                            {{review.client}}
                          </p>
                        </div>
                        <div>
                          <img :src="review?.logo?.path" :alt="review?.logo?.title" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
          </div>
            <div class="absolute bottom-0 w-2/3 z-20">
              <div class="flex justify-between relative -right-[1px]">
                <div class="ml-10 xl:ml-20 w-[280px] h-[2px] bg-slideTimerColor relative slideTimer"
                     ref="slideTimer">
                  <div ref="innerBar" class="innerBar">

                  </div>
                </div>
                <div class="flex bg-white">
                  <div class="h-[56px] flex items-center
                px-4 border-l-[1px] border-t-[1px] border-black cursor-pointer"
                       @click="showContent"
                       v-if="!isShowContent"
                  >
                    <div>
                      <img
                        src="../../assets/img/review/review-plus.svg"
                        alt=""
                      />
                    </div>
                    <p class="ml-2">читать полностью</p>
                  </div>
                  <div class="h-[56px] flex items-center
                px-4 border-l-[1px] border-t-[1px] border-black cursor-pointer"
                       @click="hideContent"
                       v-if="isShowContent"
                  >
                    <div>
                      <img
                        src="@/assets/img/review/review-minus.svg"
                        alt=""
                      />
                    </div>
                    <p class="ml-2">Свернуть</p>
                  </div>
                  <div ref="prev"
                       class="swiper-button-prev border-t-[1px]
                     border-l-[1px] border-r-[1px] border-black">
                    <img
                      src="@/assets/img/review/arrow-left.svg"
                      alt=""
                    />
                  </div>
                  <div ref="next" class="swiper-button-next border-t-[1px] border-r-[1px]
                 border-black right-[-1px]">
                    <img
                      src="@/assets/img/review/arrow-right.svg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
    <div v-if="mobileSize" class="px-0 lg:px-8 flex mt-10 border-l-0 border-r-0
    border-t-[1px] border-b-[1px] lg:border-[1px] border-black lg:hidden block">
      <div class="w-full relative">
        <div>
          <swiper
            @swiper="onSwiper"
            :slides-per-view="1"
            :space-between="5"
            :autoHeight="true"
            :navigation="{
                  prevEl: prev,
                  nextEl: next,
                }"
            :modules="modules"
            :loop="true"
          >
            <swiper-slide v-for="review in reviewedText" :key="review.slug">
              <div>
                <div class="border-b-[1px] border-black
                  xl:pr-20 pr-10 sm:pt-10 pb-5 pt-8 sm:pb-20 pl-10 relative">
                  <img
                    class="absolute sm:block hidden -right-[1px] bottom-0"
                    src="@/assets/img/review/review-strip.svg"
                    alt=""
                  />
                  <img
                    class="absolute sm:hidden block right-[-1px] bottom-0"
                    src="@/assets/img/review/mobile-strip.svg"
                    alt=""
                  />
                  <div class="flex">
                    <div class="w-2/3">
                      <h3 class="sm:text-xl mb-7">Заказчик</h3>
                      <p class="mb-7 sm:text-base text-sm">
                        {{review.client}}
                      </p>
                    </div>
                    <div class="w-1/3">
                      <img :src="review?.logo?.path" :alt="review?.logo?.title" />
                    </div>
                  </div>
                </div>
                <div>
                  <div class="xl:pr-20 pr-10 pt-10 pb-10 pl-10">
                    <div class="parag-inline">
                      <p v-if="!isShowContent">{{review.short}}</p>
                      <p v-if="isShowContent" v-html="review.review"></p>
                    </div>
                  </div>
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
          <div class=" flex justify-end relative">
            <div class="flex w-full sm:w-auto">
              <div class="h-[56px] grow 360:grow-0 flex items-center
                px-4 sm:border-l-[1px] border-l-0 border-t-[1px] border-black cursor-pointer"
                   @click="showContent"
                   v-if="!isShowContent"
              >
                <div>
                  <img
                    src="../../assets/img/review/review-plus.svg"
                    alt=""
                  />
                </div>
                <p class="ml-2 sm:text-base text-sm">читать полностью</p>
              </div>
              <div class="h-[56px] grow flex items-center
                px-4 sm:border-l-[1px] border-l-0 border-t-[1px] border-black cursor-pointer"
                   @click="hideContent"
                   v-if="isShowContent"
              >
                <div>
                  <img
                    src="@/assets/img/review/review-minus.svg"
                    alt=""
                  />
                </div>
                <p class="ml-2 sm:text-base text-sm">свернуть</p>
              </div>
              <div class="flex">
                <div ref="prev"
                     class="swiper-button-prev border-t-[1px]
                     border-l-[1px] border-r-[1px] border-black">
                  <img
                    src="@/assets/img/review/arrow-left.svg"
                    alt=""
                  />
                </div>
                <div ref="next" class="swiper-button-next border-t-[1px] sm:border-r-[1px]
                 border-black right-0">
                  <img
                    src="@/assets/img/review/arrow-right.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Autoplay } from "swiper";
// eslint-disable-next-line import/extensions
import 'swiper/css';
// eslint-disable-next-line import/extensions
import "swiper/css/navigation";
// eslint-disable-next-line import/extensions
import "swiper/css/pagination";
import { ref } from 'vue';
import sendRequest from "@/api";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  created() {
    const response = this.getReviews();
    response.then((data) => {
      this.reviews = data;
    });
  },
  mounted() {
    if (window.innerWidth <= 1024) {
      this.mobileSize = true;
    }
  },
  data() {
    return {
      isShowContent: false,
      swiper: null,
      mobileSize: false,
      reviews: null,
      reviewText: null,
    };
  },
  computed: {
    reviewedText() {
      return this.reviews.map((review) => {
        // eslint-disable-next-line no-param-reassign
        review.short = review.short.replace(/&nbsp;/g, '').replace(/\s+/g, ' ').trim();
        return review;
      });
    },
  },
  methods: {
    async getReviews() {
      try {
        const response = await sendRequest('reviews?short_limit=700');
        return response.data;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    onSwiper(swiper) {
      this.swiper = swiper;
    },
    onSlideChange() {
      this.$refs.slideTimer.classList.remove('active');
      setTimeout(() => {
        this.$refs.slideTimer.classList.add('active');
      }, 50);
    },
    mouseEnter() {
      this.$refs.innerBar.style.animationPlayState = 'paused';
      this.swiper.autoplay.pause();
    },
    mouseLeave() {
      this.$refs.innerBar.style.animationPlayState = 'running';
      this.swiper.autoplay.start();
      this.$refs.innerBar.addEventListener('animationend', () => {
        this.swiper.slideNext();
      });
    },
    showContent() {
      this.isShowContent = !this.isShowContent;
      setTimeout(() => {
        this.swiper.updateAutoHeight(1000);
      }, 10);
    },
    hideContent() {
      this.isShowContent = !this.isShowContent;
      setTimeout(() => {
        this.swiper.updateAutoHeight(1000);
      }, 10);
    },
  },
  setup() {
    const prev = ref(null);
    const next = ref(null);
    return {
      navigation: {
        nextEl: '.swiper-button-prev',
        prevEl: '.swiper-button-next',
      },
      modules: [Navigation, Autoplay],
      prev,
      next,
    };
  },
};
</script>
<style>
.swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
  content: "";
}
.swiper-button-next, .swiper-button-prev {
  top: 95%;
}
.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
  content: "";
}
.swiper-button-next, .swiper-button-prev {
  width: 56px;
  height: 56px;
  position: sticky;
}
.innerBar {
  position: absolute;
  width: 0;
  height: 2px;
  background: red;
}
.active .innerBar{
  background: black;
  animation: slideBar 5000ms linear;
}
@keyframes slideBar {
  0% { width: 0; }
  100% { width: 100%; }
}
.clamp-4 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  display: -webkit-box;
  overflow: hidden;
}
.parag-inline p {
  display: inline;
}
.parag-inline ul > li {
  display: inline;
}
.parag-inline ul {
  display: inline;
}
.parag-inline ul > li::after {
  white-space: pre;
}
</style>
