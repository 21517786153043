<template>
  <div v-if="features" class="sm:mb-32 mb-14">
    <div class="container">
      <h2 class="sm:text-3xl text-24">{{features.title}}</h2>
    </div>
    <div class="sm:container">
      <div class="grid lg:grid-cols-3 bg-black text-white mt-10">
        <div class="px-10 pt-10 sm:pb-24 pb-14 hover-circle
        overflow-hidden
        relative hover-block cursor-pointer border-gray lg:border-r-[1px] border-b-[1px]"
        v-for="item in features.items"
           :key="item.title"
        >
          <div class="z-20 absolute right-[35px] sm:bottom-[40px] bottom-[20px]">
            <div class="relative">
              <img
                class="z-20 relative"
                src="@/assets/img/benefits/plus.svg"
                alt="">
              <div class="circle z-10 absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
              </div>
            </div>
          </div>
          <img
            class="sm:block hidden absolute -right-[1px] bottom-0 opacity-50 hover:opacity-100 transition-all duration-500 z-40"
            src="@/assets/img/benefits/bene-strip.svg"
            alt="">
          <img
            class="block sm:hidden absolute lg:-right-[2px] right-0 bottom-0 opacity-50 hover:opacity-100 transition-all duration-500 z-40"
            src="@/assets/img/benefits/mobile-strip.svg"
            alt="">
          <div>
              <span
                class="sm:text-2xl text-24 relative inline-block 2xl:mb-28 sm:mb-20 mb-10">
                {{item.title}}
              </span>
              <p class="text-sm sm:text-base relative z-50">
                {{item.annotation}}
              </p>
          </div>
        </div>
      </div>
    </div>
<!--    <Transition name="slide-fade">-->
<!--    <off-canvas @close-canvas="closeCanvas" :item="item" :isShow="isShow" />-->
<!--    </Transition>-->
  </div>
</template>
<script>
// import OffCanvas from "./OffCanvas";

export default {
  // components: { OffCanvas },
  props: {
    features: {
      type: Object,
    },
  },
  data() {
    return {
      isShow: false,
      item: null,
    };
  },
  methods: {
    // closeCanvas() {
    //   this.isShow = false;
    // },
    // openCanvas(item) {
    //   this.item = item;
    //   this.isShow = true;
    // },
  },
  watch: {
    isShow() {
      if (this.isShow) {
        document.documentElement.style.overflow = 'hidden';
        return;
      }
      document.documentElement.style.overflow = 'visible';
    },
  },
};
</script>
<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.6s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
.circle {
  background: #0053B3;
  border-radius: 100%;
  width: 0;
  height: 0;
  transition: width .4s, height .4s;
}
.hover-circle:hover .circle{
  width: 160px;
  height: 160px;
  transition: width .4s, height .4s;
}
</style>
