<template>
  <div v-if="technology" class="sm:mb-32 mb-14 mt-14">
    <div class="sm:container">
      <div>
        <div class="lg:w-3/5 w-full px-8 sm:px-0">
          <h2 class="sm:text-3xl text-24">{{technology.title}}</h2>
          <p class="sm:mt-10 mt-4 text-sm sm:text-base">
            {{technology.annotation}}
          </p>
        </div>
        <div class="xl:flex justify-between mt-12 items-center 2xl:items-start hidden sm:block">
          <div class="mr-[120px] xl:w-3/5 shrink w-full">
              <the-slider v-if="techImages" :images="techImages"/>
          </div>
          <div class="xl:flex grid grid-cols-3 xl:mt-0 mt-10 xl:flex-col
          items-center justify-between grow-0 xl:w-2/5 w-full ">
            <div class="col-span-1 xl:mr-0 mr-12">
              <img src="@/assets/img/technology/tech-circle.svg" alt="">
            </div>
            <p class="xl:mt-12 col-span-2">
              {{technology.figure_text}}
            </p>
          </div>
        </div>
        <div class="sm:hidden block pt-10 p-0">
          <div class="mr-[120px] xl:w-3/5 shrink w-full">
            <the-slider v-if="techImages" :images="techImages" />
          </div>
          <div class="xl:flex grid sm:grid-cols-3 grid-cols-2 xl:mt-0 mt-10 xl:flex-col
          sm:items-center justify-between grow-0 xl:w-2/5 w-full px-8 sm:px-0">
            <div class="sm:col-span-1 xl:mr-0 mr-12">
              <img class="sm:mr-0 mr-5" src="@/assets/img/technology/tech-circle.svg" alt="">
            </div>
            <p class="xl:mt-12 sm:col-span-2">
              Стоимость строительства и ремонта дорог при использовании
              технологии «САДЭМС» ниже на 30%, чем при строительстве по
              традиционным технологиям с достижением сопоставимых технических характеристик
            </p>
          </div>
        </div>
      </div>
      <div class="mt-20 xl:px-64 px-8 sm:px-0">
        <div class="lg:grid grid-cols-2 sm:text-base text-sm">
          <div class="flex items-center gap-3 mb-14"
               v-for="(feauter, index) in techFeatures" :key="index">
            <img class="max-w-[130px]" :src="feauter.icon" alt="">
            <p>{{feauter.annotation}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TheSlider from "@/components/UI/TheSlider";

export default {
  components: { TheSlider },
  props: {
    technology: {
      type: Object,
    },
    techImages: {
      type: Array,
    },
    techFeatures: {
      type: Array,
    },
  },
};
</script>
