<template>
  <div v-if="mainPagedata">
    <the-main :title="mainPagedata.title">
      <template v-slot:img-circle>
        <img
          class="absolute right-0 -bottom-[180px] hidden md:block"
          src="../assets/img/main/main-circle-homepage.png"
          alt="">
      </template>
      <template v-slot:mobile-img-circle>
        <img
          class="absolute right-4 bottom-0 sm:bottom-4 md:hidden"
          src="@/assets/img/main/mobile-circle-homepage.png"
          alt="">
      </template>
    </the-main>
    <the-video :video="mainPagedata.banner_media.path" />
    <main-description :main-description="mainPagedata.main_text"/>
    <the-benefits :features="mainPagedata.features" />
    <the-objects :project-score="mainPagedata.project_score"/>
    <the-technology :technology="mainPagedata.technology"
                    :tech-images="mainPagedata.technology_images"
                    :tech-features="mainPagedata.technology_features"/>
    <the-reviews/>
  </div>
</template>

<script>
import TheBenefits from "@/components/HomePage/TheBenefits";
import MainDescription from "@/components/HomePage/MainDescription";
import TheVideo from "@/components/HomePage/TheVideo";
import TheMain from "@/components/UI/TheMain";
import TheObjects from "@/components/HomePage/TheObjects";
import TheTechnology from "@/components/HomePage/TheTechnology";
import TheReviews from "@/components/HomePage/TheReviews";
import sendRequest from "@/api";

export default {
  name: "HomeView",
  components: {
    TheMain,
    TheVideo,
    MainDescription,
    TheTechnology,
    TheObjects,
    TheBenefits,
    TheReviews,
  },
  created() {
    const response = this.getMainTitle();
    response.then((data) => {
      this.mainPagedata = data;
    });
  },
  data() {
    return {
      mainPagedata: null,
    };
  },
  methods: {
    async getMainTitle() {
      try {
        const response = await sendRequest('mainpage');
        return response.data;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
  },
};
</script>
