<template>
  <router-link v-if="post" :to="`/posts/post/${post.slug}`">
    <div class="xl:mb-24 mb-12">
      <img class="w-full" src="@/assets/img/post-item/post-img-1.png" alt="">
      <div class="col-span-2">
        <div class="mb-2 mt-8 flex items-center">
          <div class="mr-4">
            <img :src="post.author.photo.path" alt="">
          </div>
          <div class="sm:text-base text-12 sm:block hidden">
            <p>{{post.author.name}} &#8226;
              <span class="text-black/40">{{post.published_at}}</span>
            </p>
            <p class="text-black/60">{{post.author.post}}</p>
          </div>
          <div class="sm:text-base text-12 block sm:hidden">
            <p class="text-black/40">{{post.published_at}}</p>
            <p>{{post.author.name}}</p>
            <p class="text-black/60">{{post.author.post}}</p>
          </div>
        </div>
        <h2 class="md:text-2xl text-24">
          {{post.name}}
        </h2>
        <p class="mt-2 text-12 sm:text-base">{{post.summary}}</p>
        <div class="sm:mt-12 mt-4 flex flex-wrap text-12 sm:text-base">
          <a :href="tag.slug" class="whitespace-nowrap border-gray/[.3] border-[1px]
            text-black/50 rounded-3xl py-2 px-5 mr-5 mb-2"
          v-for="tag in post.tags"
             :key="tag.slug"
          >
            {{tag.name}}
          </a>
        </div>
      </div>
    </div>
  </router-link>
</template>
<script>
export default {
  props: {
    post: {
      type: Object,
    },
  },
};
</script>
