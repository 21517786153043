<template>
  <div class="bg-black text-white relative">
    <img class="absolute xl:left-0 right-0 hidden lg:block"
         src="@/assets/img/footer/footer-strip.svg" alt="">
    <img class="absolute left-0 top-12 md:hidden block"
         src="@/assets/img/footer/mobile-circle.png" alt="">
    <div class="container px-8 2xl:px-36 sm:pt-[75px] md:pb-28 sm:pb-14 py-12 relative">
      <img class="absolute right-0 2xl-right-[116px] top-0 hidden md:block"
           src="@/assets/img/footer/footer-circle.svg" alt="">
      <img class="absolute bottom-0 right-0 sm:hidden block"
           src="@/assets/img/footer/mobile-strip.svg" alt="">
      <div class="lg:grid block justify-between grid-cols-2 h-full z-20 relative lg:mt-[40px]">
        <div class="">
          <span class="lg:text-2xl sm:text-32 text-24">
            {{footerText}}
          </span>
        </div>
        <div class="mt-4 lg:mt-0" v-if="SiteSettings">
          <h3 class="text-sm sm:text-base">Свяжитесь с нами</h3>
          <a class="lg:text-3xl sm:text-32 text-24 mt-4
          inline-block border-b-[1px] border-[#ffffff] whitespace-nowrap" :href="`tel:${SiteSettings.phone}`">
            {{SiteSettings.phone}}
          </a>
          <div class="mt-10">
            <a class="border-[1px] border-[#ffffff] inline-block py-[15px] px-[30px]" :href="`mailto:${SiteSettings.email}`">
              Написать письмо
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="border-t-[1px] border-[#414141]">
      <div class="container">
        <div class="360:flex text-center 360:text-left block justify-between items-center py-7">
          <div>
            <p class="text-sm">© Битум Рус, 2012 — 2022</p>
          </div>
          <div class="flex justify-center 360:justify-start mt-4 360:mt-0">
            <img src="@/assets/img/footer/gromit-icon.svg" alt="">
            <a href="https://grom-it.ru/" target="_blank" class="text-sm ml-2">
              Grom IT SOFT
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import sendRequest from "@/api";

export default {
  props: {
    SiteSettings: {
      type: Object,
    },
  },
  created() {
    const response = this.getVideo();
    response.then((data) => {
      this.footerText = data.footer_title;
    });
  },
  data() {
    return {
      footerText: null,
    };
  },
  methods: {
    async getVideo() {
      try {
        const response = await sendRequest('mainpage');
        return response.data;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
  },
};
</script>
