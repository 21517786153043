<template>
  <div v-if="posts" class="container mt-10">
    <h3 class="sm:text-xl mb-8 text-base">Популярные тематики</h3>
    <popular-tags @get-slug="getPageSlug"/>
    <div class="sm:mt-24 mt-10">
      <div class="grid md:grid-cols-2 gap-x-20">
        <TransitionGroup name="list">
          <post-item v-for="post in posts" :key="post.slug" :post="post"/>
        </TransitionGroup>
      </div>
    </div>
  </div>
  <div v-if="postsData" class="container py-8">
    <paginate
      class="md:flex hidden relative md:pb-0 pb-16 justify-center"
      v-model="pageNumModel"
      :page-count="postsData?.meta?.last_page"
      :page-range="2"
      :disabled-class="'opacity-30'"
      :click-handler="getPageNumber"
      :container-class="'pagi-container'"
      :page-class="'page-item'"
      :page-link-class="'border-t-[1px] last:border-r-[1px] border-r-0 border-b-[1px] border-l-[1px] border-black py-4 px-6 inline-block ml-[-1px] cursor-pointer'"
      :active-class="'bg-black text-white'"
      prev-text='<div class="flex justify-between items-center border-[1px] border-black py-4 px-6 prev-btn">
                <img class="rotate-180" src="./img/arrow-right.svg" alt="">
                <p class="ml-3">назад</p>
                </div>'
      next-text='<div class="flex justify-between items-center border-[1px] border-black py-4 px-6 next-btn">
                <p class="mr-3">вперед</p>
                <img src="./img/arrow-right.svg" alt="">
                </div>'
    >
    </paginate>
    <div class="block md:hidden">
      <div class="flex overflow-auto justify-center">
        <div @click="getPageNumber(pageNum)" class="border-t-[1px] last:border-r-[1px] border-r-0 border-b-[1px] border-l-[1px] border-black py-4 px-6 ml-[-1px] first:ml-0 cursor-pointer"
             v-for="pageNum in postsData?.meta?.last_page" :key="pageNum"
             :class="{'bg-black': page == pageNum, 'text-white': page == pageNum}">
          {{pageNum}}
        </div>
      </div>
      <div class="flex justify-center mt-8">
        <button @click="getPageNumber(+page - 1)"
                class="mr-[-1px] flex justify-between items-center border-[1px] border-black py-4 px-6 prev-btn w-full"
                :disabled="page == 1"
                :class="{'opacity-40': page == 1}">
          <img class="rotate-180 mr-[-1px]" src="@/assets//img/pagination/arrow-right.svg" alt="">
          <p class="ml-3">назад</p>
        </button>
        <button @click="getPageNumber(+page + 1)"
                class="flex justify-between items-center border-[1px] border-black py-4 px-6 next-btn w-full"
                :disabled="page == postsData?.meta?.last_page"
                :class="{'opacity-40': page == postsData?.meta?.last_page}">
          <p class="mr-3">вперед</p>
          <img src="@/assets//img/pagination/arrow-right.svg" alt="">
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import Paginate from "vuejs-paginate-next";
import PopularTags from "./PopularTags";
import PostItem from "./PostItem";

export default {
  components: {
    PopularTags,
    PostItem,
    Paginate,
  },
  props: {
    postsData: {
      type: Object,
    },
    posts: {
      type: Object,
    },
    page: {
      type: [String, Number],
      default: 1,
    },
  },
  mounted() {
    this.isExtremePage();
  },
  computed: {
    pageNumModel: {
      get() {
        return this.page;
      },
      set(value) {
        this.$emit('update:page', value);
      },
    },
  },
  emits: ['get-page-slug', 'get-page-num', 'update:modelValue'],
  methods: {
    getPageSlug(slug) {
      this.$emit('get-page-slug', slug);
    },
    getPageNumber(pageNum) {
      this.$emit('get-page-num', pageNum);
    },
    isExtremePage() {
      const prevBtn = document.querySelector('.prev-btn');
      if (!prevBtn) return;
      if (this.page === 1 || this.page === '1') {
        prevBtn.classList.add('opacity-50');
      }
      const nextBtn = document.querySelector('.next-btn');
      if (this.page === this.postsData.meta?.last_page.toString()) {
        nextBtn.classList.add('opacity-50');
      }
    },
  },
  watch: {
    page() {
      this.isExtremePage();
    },
  },
};
</script>
<style>
.list-move, /* apply transition to moving elements */
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
}

.list-leave-active {
  position: absolute;
}
.page-item {
  cursor: pointer;
}
.page-item:first-child {
  margin-right: auto;
}
.page-item:last-child {
  margin-left: auto;
}
.pagi-container .disabled {
  opacity: 0.4;
}
@media(max-width: 769px) {
  .page-item:first-child {
    position: absolute;
    left: 0;
    bottom: 0;
  }
  .page-item:last-child {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
</style>
