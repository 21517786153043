<template>
  <div class="flex flex-nowrap overflow-auto whitespace-nowrap text-12 sm:text-base">
    <div :data-slug="tag.slug" v-for="tag in tags"
          :key="tag.slug"
         :class="{'bg-black': $route.query.tags === tag.slug,
         'text-white': $route.query.tags === tag.slug}"
          class="border-gray/[.3] border-[1px] rounded-3xl py-2 px-5 mr-5 cursor-pointer"
          @click="getPostsByTag"
    >
    {{tag.name}}
  </div>
  </div>
</template>
<script>
import sendRequest from "@/api";

export default {
  data() {
    return {
      tags: null,
    };
  },
  created() {
    const response = this.getTags();
    response.then((data) => {
      this.tags = data;
    });
  },
  methods: {
    async getTags() {
      try {
        const response = await sendRequest('blog/tags');
        return response.data;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    getPostsByTag(e) {
      this.$emit('get-slug', e.target.dataset.slug);
    },
  },
};
</script>
