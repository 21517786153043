import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView';
import PostsView from '../views/PostsView';
import PostView from '../views/PostView';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/posts',
    name: 'posts',
    component: PostsView,
  },
  {
    path: '/posts/post/:slug',
    name: 'post',
    component: PostView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // eslint-disable-next-line consistent-return
  scrollBehavior(to, from) {
    if (from.name === 'home') {
      return {
        el: to.hash,
        behavior: 'smooth',
      };
    }
    if (to.hash) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            el: to.hash,
            behavior: 'smooth',
          });
        }, 700);
      });
    }
  },
});

export default router;
