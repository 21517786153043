<template>
  <div v-if="headerPost" class="border-b-[2px] border-black/20 sm:mb-20 sm:pb-14 pb-10">
    <div class="container relative z-20">
      <router-link :to="`posts/post/${headerPost.slug}`">
        <div class="lg:grid grid-cols-5 hidden">
          <div class="col-span-2 mt-20">
            <button class="bg-blue py-2 px-7 text-white letter-spacing: 0.1em;">
              НОВОЕ В БЛОГЕ
            </button>
            <div class="mb-4 mt-8 flex items-center">
              <div class="mr-4">
                <img :src="headerPost.author.photo.path" alt="">
              </div>
              <div>
                <p>{{ headerPost.author.name }} &#8226; <span
                  class="text-black/40">{{ headerPost.published_at }}</span></p>
                <p class="text-black/60">{{ headerPost.author.post }}</p>
              </div>
            </div>
            <h2 class="text-2xl">{{ headerPost.name }}</h2>
            <p class="mt-4">{{headerPost.summary}}</p>
            <div class="mt-12">
            <span :key="tag.slug" v-for="tag in headerPost.tags"
                  class="border-gray/[.3] border-[1px] text-black/50 rounded-3xl py-2 px-5 mr-5">
              {{ tag.name }}
            </span>
            </div>
          </div>
          <div class="col-span-3 relative bottom-[95px]">
            <div class="relative">
              <swiper
                @swiper="onSwiper"
                :slides-per-view="1"
                :space-between="25"
                :lazy="{
                          loadPrevNext: true,
                          }"
                :autoHeight="true"
                :modules="modules"
                :loop="true"
                :autoplay="{
                    delay: 5000,
                    disableOnInteraction: false,
                    }"
              >
                <swiper-slide class="h-full" ref="swiper-idx" v-for="slide in headerPost.gallery" :key="slide">
                  <div>
                    <img class="w-full" :src="slide.path" alt="">
                  </div>
                </swiper-slide>
              </swiper>
            </div>
          </div>
        </div>
      </router-link>
      <router-link :to="`posts/post/${headerPost.slug}`">
        <div class="block lg:hidden">
          <div class="mt-10">
            <div class="mb-4 flex items-center">
              <div class="mr-4">
                <img class="min-w-[44px]" :src="headerPost.author.photo.path" alt="">
              </div>
              <div class="sm:text-base text-12">
                <p>
                  {{ headerPost.author.name }} &#8226;
                  <span class="text-black/40">
                    {{ headerPost.published_at }}
                  </span>
                </p>
                <p class="text-black/60">{{ headerPost.author.post }}</p>
              </div>
            </div>
            <h2 class="sm:text-2xl text-24">
              {{ headerPost.name }}
            </h2>
            <p class="mt-4 text-12 sm:text-base">{{headerPost.summary}}</p>
          </div>
          <div class="relative mt-10 md:block hidden">
            <img class="ml-auto" src="@/assets/img/header-posts/header-posts.png" alt="">
            <button class="bg-blue py-2 px-7 text-white
          letter-spacing: 0.1em absolute top-0 left-0">НОВОЕ В БЛОГЕ
            </button>
          </div>
        </div>
      </router-link>
    </div>
    <div class="relative mt-10 block md:hidden">
      <div class="relative">
        <swiper
          @swiper="onSwiper"
          :slides-per-view="1"
          :space-between="25"
          :lazy="{
                          loadPrevNext: true,
                          }"
          :modules="modules"
          :loop="true"
          :autoplay="{
                    delay: 5000,
                    }"
        >
          <swiper-slide class="h-full" ref="swiper-idx" v-for="slide in headerPost.gallery" :key="slide">
            <div class="relative">
              <img :src="slide.path" alt="">
              <button class="bg-blue py-2 px-7 text-white
          letter-spacing: 0.1em absolute top-0 left-0 sm:text-base text-12">НОВОЕ В БЛОГЕ
              </button>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay, Lazy } from "swiper";
// eslint-disable-next-line import/extensions
import 'swiper/css';
// eslint-disable-next-line import/extensions
// eslint-disable-next-line import/extensions
// eslint-disable-next-line import/extensions
import "swiper/css/lazy";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    headerPost: {
      type: Object,
    },
  },
  data() {
    return {
      swiper: null,
    };
  },
  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
    },
  },
  setup() {
    return {
      modules: [Autoplay, Lazy],
    };
  },
};
</script>
