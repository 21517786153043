<template>
  <div class="bg-black text-white py-5 relative">
    <Transition name="fade">
    <mobile-menu :isShow="isShow" @close-mobile-menu="closeMobileMenu" />
    </Transition>
    <nav class="container relative z-20">
      <div class="md:flex items-center justify-between hidden">
        <div class="flex items-center">
          <router-link class="mr-8 flex items-center" to="/">
            <img class="mr-5" src="@/assets/img/header/header-logo.svg" alt="">
            <p>Битум Рус</p>
          </router-link>
          <div>
            <router-link to="/#objects" class="mr-8">Наши объекты</router-link>
            <router-link class="mr-12" to="/posts">Блог</router-link>
          </div>
        </div>
        <div v-if="SiteSettings">
          <a class="mr-8" :href="`mailto:${SiteSettings.email}`">{{SiteSettings.email}}</a>
          <a :href="`tel:${SiteSettings.phone}`">{{SiteSettings.phone}}</a>
        </div>
      </div>
      <div class="flex items-center justify-between md:hidden">
        <div class="flex items-center">
          <router-link class="mr-12 flex items-center" to="/">
            <img class="mr-5" src="@/assets/img/header/header-logo.svg" alt="">
            <p>Битум Рус</p>
          </router-link>
        </div>
        <div @click="isShow = !isShow">
          <img src="@/assets/img/header/burger.svg" alt="">
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
import MobileMenu from "@/components/UI/MobileMenu";

export default {
  components: {
    MobileMenu,
  },
  props: {
    SiteSettings: {
      type: Object,
    },
  },
  data() {
    return {
      isShow: false,
    };
  },
  methods: {
    closeMobileMenu() {
      this.isShow = false;
    },
  },
};
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
