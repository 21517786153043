<template>
  <div v-if="post">
    <div class="container my-14 sm:block hidden">
      <div class="lg:grid grid-cols-4 sm:block hidden">
        <div class="col-span-2">
          <button class="bg-blue py-2 px-7 text-white letter-spacing: 0.1em;">
            НОВОЕ В БЛОГЕ
          </button>
          <div class="mb-4 mt-8 flex items-center">
            <div class="mr-4">
              <img :src="post.author.photo.path" alt="">
            </div>
            <div>
              <p>{{post.author.name}} &#8226; <span class="text-black/40">
                {{post.published_at}}</span></p>
              <p class="text-black/60">{{post.author.post}}</p>
            </div>
          </div>
          <h2 class="text-2xl">{{ post.name }}</h2>
          <p class="mt-4">{{post.summary}}</p>
          <div class="mt-12">
            <a v-for="tag in post.tags"
               :key="tag.slug"
               href=""
               class="whitespace-nowrap border-gray/[.3] border-[1px] text-black/50 rounded-3xl py-2 px-5 mr-5 mb-2">
              {{tag.name}}
            </a>
          </div>
        </div>
        <div class="col-span-2 relative lg:mt-0 mt-14">
          <swiper
            @swiper="onSwiper"
            :slides-per-view="1"
            :space-between="25"
            :lazy="{
                          loadPrevNext: true,
                          }"
            :autoHeight="true"
            :modules="modules"
            :loop="true"
            :autoplay="{
                    delay: 5000,
                    disableOnInteraction: false,
                    }"
          >
            <swiper-slide class="h-full" ref="swiper-idx" v-for="slide in post.gallery" :key="slide">
              <div>
                <img class="w-full" :src="slide.path" alt="">
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
    <div class="block sm:hidden">
      <div class="col-span-3 relative lg:mt-0">
        <swiper
          @swiper="onSwiper"
          :slides-per-view="1"
          :space-between="25"
          :lazy="{
                          loadPrevNext: true,
                          }"
          :autoHeight="true"
          :modules="modules"
          :loop="true"
          :autoplay="{
                    delay: 5000,
                    disableOnInteraction: false,
                    }"
        >
          <swiper-slide class="h-full" ref="swiper-idx" v-for="slide in post.gallery" :key="slide">
            <div>
              <img class="w-full" :src="slide.path" alt="">
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div class="col-span-2 px-8 mt-14">
        <button class="bg-blue py-2 px-7 text-white letter-spacing: 0.1em;">
          НОВОЕ В БЛОГЕ
        </button>
        <div class="mb-4 mt-8 flex items-center">
          <div class="mr-4">
            <img :src="post.author.photo.path" alt="">
          </div>
          <div>
            <p class="text-12">
              {{post.author.name}} &#8226;
              <span class="text-black/40">{{post.published_at}}</span></p>
            <p class="text-black/60 text-12">{{post.author.post}}</p>
          </div>
        </div>
        <h2 class="text-24">{{ post.name }}</h2>
        <p class="mt-4">{{post.summary}}</p>
        <div class="mt-12 text-12 sm:mt-12 mt-4 flex flex-wrap text-12 sm:text-base">
          <a v-for="tag in post.tags"
             :key="tag.slug"
             href=""
             class="whitespace-nowrap border-gray/[.3] border-[1px] text-black/50 rounded-3xl py-2 px-5 mr-5 mb-2">
            {{tag.name}}
          </a>
        </div>
      </div>
    </div>
  </div>
  <div v-if="post" class="container xl:px-80 my-14 pb-16 border-b-[2px] border-gray/50 constructor">
    <div v-for="(postContent, idx) in post.content" :key="idx">
      <div v-if="postContent._group === 'annotation'"
           class="py-6 border-t-[1px] border-black border-b-[1px] mb-6">
        <div
           v-html="postContent.value"
           class="sm:text-xl text-base"></div>
      </div>
      <div v-if="postContent._group === 'text'"
           class="py-6">
        <div v-html="postContent.value"></div>
      </div>
      <div v-if="postContent._group === 'images'"
           class="py-6">
        <div class="mb-10" v-for="image in postContent.value" :key="image">
          <img class="w-full" :src="image" alt="">
        </div>
      </div>
    </div>

    <div class="flex items-stretch">
      <a class="border-[1px] p-[12px] border-black" href="">
        Поделиться
      </a>
      <a class="px-4 border-[1px] border-black ml-[-1px] flex" href="">
        <img src="@/assets/img/constructor/vk.svg" alt="">
      </a>
      <a class="px-4 border-[1px] border-black ml-[-1px] flex" href="">
        <img src="@/assets/img/constructor/ok.svg" alt="">
      </a>
      <a class="px-4 border-[1px] border-black ml-[-1px] flex" href="">
        <img src="@/assets/img/constructor/tg.svg" alt="">
      </a>
    </div>
  </div>
  <random-posts :random-posts="randomPosts"/>
</template>
<script>

// import TheSlider from "@/components/UI/TheSlider";
import sendRequest from "@/api";
import RandomPosts from "@/components/UI/RandomPosts";
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay, Lazy } from "swiper";
// eslint-disable-next-line import/extensions
import 'swiper/css';
// eslint-disable-next-line import/extensions
// eslint-disable-next-line import/extensions
// eslint-disable-next-line import/extensions
import "swiper/css/lazy";

export default {
  name: 'PostView',
  components: {
    RandomPosts,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      post: null,
      randomPosts: null,
      swiper: null,
    };
  },
  created() {
    this.getPost();
  },
  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
    },
    async getPost() {
      try {
        const response = await sendRequest(`blog/posts/${this.$route.params.slug}`);
        this.post = response.data;
        this.getRandomPosts();
        return response.data;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    async getRandomPosts() {
      try {
        const response = await sendRequest(`blog/posts?exclude=${this.post.slug}&random=true&per_page=2`);
        this.randomPosts = response.data;
        return response.data;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
  },
  watch: {
    '$route.params.slug': function () {
      this.getPost();
      this.getRandomPosts();
      setTimeout(() => {
        window.scrollTo(0, 100);
      }, 300);
    },
  },
  setup() {
    return {
      modules: [Autoplay, Lazy],
    };
  },
};
</script>
