<template>
  <div v-if="images" class="relative flex sm:block">
    <swiper
      @swiper="onSwiper"
      :slides-per-view="1"
      :space-between="25"
      :lazy="{
        loadPrevNext: true,
        }"
      :autoplay="{
                delay: 5000,
                disableOnInteraction: false,
              }"
      :autoHeight="true"
      :navigation="{
                  prevEl: prev,
                  nextEl: next,
                }"
      :modules="modules"
      :loop="true"
      @mouseenter="mouseEnter"
      @mouseleave="mouseLeave"
    >
      <swiper-slide class="h-full sm:bg-white bg-black sm:block flex items-center justify-center" ref="swiper-idx" v-for="image in images" :key="image">
          <div v-if="image.type === 'video' " class="video-slider">
            <video autoplay class="h-full w-full object-cover" preload="auto" loop controls :src="image.path" muted>
            </video>
          </div>
          <div v-if="image.type === 'image' ">
            <img :data-src="image.path" alt=""
                 class="w-full min-h-[360px] object-cover sm:h-full swiper-lazy">
          </div>
      </swiper-slide>
    </swiper>
    <div class="absolute bottom-0 right-0">
      <div class="flex">
        <div ref="prev"
             class="swiper-button-prev border-t-[1px]
                     border-l-[1px] border-b-[1px] border-black bg-white border-r-[1px]">
          <img
            src="@/assets/img/review/arrow-left.svg"
            alt=""
          />
        </div>
        <div ref="next" class="swiper-button-next border-t-[1px] border-b-[1px]
                 border-black bg-white border-r-[1px] right-0">
          <img
            src="@/assets/img/review/arrow-right.svg"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Lazy, Autoplay } from "swiper";
// eslint-disable-next-line import/extensions
import 'swiper/css';
// eslint-disable-next-line import/extensions
import "swiper/css/navigation";
// eslint-disable-next-line import/extensions
import "swiper/css/pagination";
// eslint-disable-next-line import/extensions
import "swiper/css/lazy";

import { ref } from 'vue';

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    images: {
      type: Array,
    },
  },

  data() {
    return {
      swiper: null,
      isPaused: true,
    };
  },
  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
    },
    mouseEnter() {
      this.swiper.autoplay.pause();
    },
    mouseLeave() {
      this.swiper.autoplay.start();
    },
  },
  setup() {
    const prev = ref(null);
    const next = ref(null);
    return {
      navigation: {
        nextEl: '.swiper-button-prev',
        prevEl: '.swiper-button-next',
      },
      modules: [Navigation, Lazy, Autoplay],
      prev,
      next,
    };
  },
};
</script>
<style scoped>
.swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
  content: "";
}

.swiper-button-next, .swiper-button-prev {
  top: 95%;
}

.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
  content: "";
}

.swiper-button-next, .swiper-button-prev {
  width: 56px;
  height: 56px;
  position: sticky;
}
#map > ymaps {
  width: 100%;
}
.slick-track {
  display: flex;
}
/*.video-background iframe{*/
/*  width: 100%!important;*/
/*  height: 100%!important;*/
/*}*/
.video-background {
  width: 100%!important;
  height: 100%!important;
}
/*.video-background iframe {*/
/*  position: absolute;*/
/*  top: 50%;*/
/*  left: 50%;*/
/*  width: 100%;*/
/*  height: 100%;*/
/*  transform: translate(-50%, -50%);*/
/*}*/
@media (min-width: 640px) {
  @media (max-aspect-ratio: 16/9) {
    .video-background iframe {
      width: 177.78vh;
    }
  }
  @media (max-width: 640px) {
    .video-background iframe {
      height: 360px;
    }
  }
  @media (min-aspect-ratio: 16/9) {
    .video-background iframe {
      height: 26.25vw;
    }
  }
}
</style>
