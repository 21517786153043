<template>
  <div class="flex flex-col h-screen justify-between">
    <div>
      <the-header :SiteSettings="siteSettings" />
      <Transition>
      <router-view/>
      </Transition>
    </div>
    <the-footer :SiteSettings="siteSettings" />
  </div>
</template>

<script>
import sendRequest from "@/api";
import TheFooter from './components/UI/TheFooter';
import TheHeader from './components/UI/TheHeader';

export default {
  name: 'App',
  components: {
    TheFooter,
    TheHeader,
  },
  created() {
    const response = this.getSiteSettings();
    response.then((data) => {
      this.siteSettings = data;
    });
  },
  data() {
    return {
      siteSettings: null,
    };
  },
  methods: {
    async getSiteSettings() {
      try {
        const response = await sendRequest('settings ');
        return response.data;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
  },
};
</script>
<style>
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
