<template>
  <div v-if="video" class="sm:mb-24 mb-12 relative z-20 sm:h-full relative z-20 w-full video">
    <div class="h-[360px] relative">
      <video autoplay ref="video" class="h-full w-full object-cover" preload="auto" loop :src="video" muted>
      </video>
      <button v-if="isPaused" class="absolute top-1/2 left-1/2 w-[50px] -translate-x-1/2 -translate-y-1/2 sm:hidden block" type="button" ref="play_button">
        <img src="@/assets/img/video/play-button.svg" alt="">
      </button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    video: {
      type: String,
    },
  },
  data() {
    return {
      isPaused: true,
    };
  },
  mounted() {
    const playButton = this.$refs.play_button;
    playButton.addEventListener("click", () => {
      if (this.$refs.video.paused === true) {
        this.$refs.video.play();
        this.isPaused = false;
      } else {
        this.$refs.video.pause();
        this.isPaused = true;
      }
    });
  },
};
</script>
<style scoped>
video {
  margin: 0 auto;
}

:-webkit-full-screen #myvideo {
  width: 100%;
  height: 100%;
}

</style>
