<template>
<div v-if="objectData">
  <div v-if="!mobileSize" class="w-full h-screen off-canvas top-0 z-20">
    <div class="relative h-full">
      <swiper
        class="h-full"
        @swiper="onSwiper"
        :slides-per-view="1"
        :space-between="25"
        :initialSlide="pinIdx"
        :lazy="{
        loadPrevNext: true,
        }"
        :navigation="{
                  prevEl: prev,
                  nextEl: next,
                }"
        :modules="modules"
      >
        <swiper-slide v-for="project in projects"
                      :key="project.slug"
                      :data-slide-index="project.slug">
          <div class="ml-auto bg-white relative">
            <div class="cursor-pointer" @click="this.$emit('close-modal')">
              <img
                class="absolute bg-white
                right-0 top-0 p-4 border-black border-l-[1px] border-b-[1px]"
                src="@/assets/img/off-canvas/close.svg"
                alt="">
            </div>
            <div class="flex">
              <div class="w-2/3">
                <modal-yandex-map class="h-screen" :coords="project.coords" :slug="project.slug" />
              </div>
              <div class="w-1/3 border-l-[1px] border-black">
                <div>
                  <img class="w-full" :src="project?.image?.path" alt="">
                </div>
                <div class="py-10 px-12">
                  <h2 class="2xl:text-2xl lg:text-32 text-24">{{project.name}}</h2>
                  <p class="mt-4 lg:text-base text-sm" v-html="project.body"></p>
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
      <div class="absolute bottom-0 right-0">
        <div class="flex">
          <div ref="prev"
               class="swiper-button-prev border-t-[1px]
                     border-l-[1px] border-b-[1px] border-black bg-white border-r-[1px]">
            <img
              src="@/assets/img/review/arrow-left.svg"
              alt=""
            />
          </div>
          <div ref="next" class="swiper-button-next border-t-[1px] border-b-[1px]
                 border-black bg-white border-r-[1px] right-0">
            <img
              src="@/assets/img/review/arrow-right.svg"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="w-full h-full overflow-auto off-canvas top-0">
    <div class="relative">
      <swiper
        @swiper="onSwiper"
        :auto-height="true"
        :slides-per-view="1"
        :initialSlide="pinIdx"
        :navigation="{
                  prevEl: prev,
                  nextEl: next,
                }"
        :modules="modules"
      >
        <swiper-slide v-for="project in projects"
                      :key="project.slug"
                      :data-slide-index="project.slug">
          <div class="ml-auto bg-white">
            <div class="cursor-pointer relative z-20" @click="this.$emit('close-modal')">
              <img
                class="absolute bg-white
                right-0 top-0 sm:p-4 p-[10px] border-black border-l-[1px] border-b-[1px]"
                src="@/assets/img/off-canvas/close.svg"
                alt="">
            </div>
            <div>
                <div class="relative">
                  <div>
                    <img class="w-full" :src="project?.image?.path" alt="">
                  </div>
                  <div class="absolute bottom-0 right-0">
                    <div class="flex">
                      <div ref="prev"
                           class="sm:w-[56px] w-full
                           sm:h-[56px] w-[40px] h-[40px] border-t-[1px]
                     border-l-[1px] border-b-[1px] border-black bg-white border-r-[1px]
                     flex justify-center items-center">
                        <img
                          src="@/assets/img/review/arrow-left.svg"
                          alt=""
                        />
                      </div>
                      <div ref="next" class="sm:w-[56px] w-full
                           sm:h-[56px] w-[40px] h-[40px] border-t-[1px] border-b-[1px]
                 border-black bg-white border-r-[1px] right-0 flex justify-center items-center">
                        <img
                          src="@/assets/img/review/arrow-right.svg"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="py-10 px-12">
                  <h2 class="2xl:text-2xl lg:text-32 text-24">{{project.name}}</h2>
                  <p class="mt-4 lg:text-base text-sm" v-html="project.body"></p>
                </div>
              </div>
              <div class="w-full h-[400px]">
                <modal-yandex-map class="w-full h-full"
                                  :coords="project.coords" :slug="project.slug" />
              </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</div>
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { ref } from "vue";
import { Lazy, Navigation } from "swiper";
import ModalYandexMap from "@/components/HomePage/ModalYandexMap";

export default {
  components: {
    ModalYandexMap,
    Swiper,
    SwiperSlide,
  },
  props: {
    isShow: {
      type: Boolean,
    },
    objectData: {
      type: Object,
    },
    projects: {
      type: Object,
    },
    pinIdx: {
      type: Number,
    },
  },
  emits: ['close-modal'],
  created() {
    if (window.innerWidth <= 1025) {
      this.mobileSize = true;
    }
  },
  data() {
    return {
      swiper: null,
      mobileSize: false,
    };
  },
  methods: {
    closeCanvas() {
      this.$emit('close-modal');
    },
    onSwiper(swiper) {
      this.swiper = swiper;
      setTimeout(() => {
        this.swiper.updateAutoHeight(1000);
      }, 100);
    },
  },
  setup() {
    const prev = ref(null);
    const next = ref(null);
    return {
      navigation: {
        nextEl: '.swiper-button-prev',
        prevEl: '.swiper-button-next',
      },
      modules: [Navigation, Lazy],
      prev,
      next,
    };
  },
};
</script>
<style>
.off-canvas {
  position: fixed;
  height: 100%;
  width: 100%;
}
.modalMaps .ymaps-2-1-79-controls__control {
  position: absolute !important;
  right: 20px !important;
  top: 420px !important;
  left: auto !important;
}
@media(max-width: 1025px) {
  .modalMaps .ymaps-2-1-79-controls__control {
    position: absolute !important;
    right: 20px !important;
    top: 170px !important;
    left: auto !important;
  }
}
.swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
  content: "";
}

.swiper-button-next, .swiper-button-prev {
  top: 95%;
}

.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
  content: "";
}

</style>
