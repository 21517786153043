<template>
  <div class="sm:mb-32 mb-14" id="objects">
    <div class="container">
      <div class="grid md:grid-cols-3 gap-10">
        <h2 class="sm:text-3xl text-24">Наши объекты</h2>
        <div>
          <h3 class="sm:text-2xl text-24">{{ projects.length }}</h3>
          <p class="mt-3 sm:text-base text-sm">
            Объекта выполнены с применением технологии «САДЭМС» в России
          </p>
        </div>
        <div v-if="projectScore">
          <h3 class="sm:text-2xl text-24">{{ projectScore.title }}</h3>
          <p class="mt-3 sm:text-base text-sm">
            {{ projectScore.annotation }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="relative">
    <div class="sm:container">
      <div class="mt-16 object-map">
        <yandex-map
          :coordinates="coordinates"
          :projectSlugs="projectSlugs"
          @click-on-pin="getObjData"
        />
      </div>
    </div>
    <Transition name="fade">
      <modal-map
        v-if="objectData"
        :objectData="objectData"
        :projects="projects"
        :pinIdx="pinIdx"
        @close-modal="closeModal"
      />
    </Transition>
  </div>
</template>
<script>
import sendRequest from "@/api";
import YandexMap from "@/components/UI/YandexMap";
import ModalMap from "@/components/UI/ModalMap";
/* eslint-disable */
export default {
  components: { YandexMap, ModalMap },
  props: {
    projectScore: {
      type: Object,
    },
  },
  updated() {
    this.$nextTick(() => {
      if (this.$route.hash) {
        const el = document.querySelector(this.$route.hash);
        // eslint-disable-next-line no-unused-expressions
        el && el.scrollIntoView();
      }
    });
  },
  mounted() {
    const getMap = this.getMapData();
    getMap.then((data) => {
      this.projects = data;
      this.projects.forEach((project) => {
        this.coordinates.push(project.coords[0]);
        this.projectSlugs.push(project.slug);
      });
    });
  },
  data() {
    return {
      projectText: null,
      projects: null,
      coordinates: [],
      projectSlugs: [],
      objectData: null,
      pinIdx: null,
    };
  },
  methods: {
    async getMapData() {
      try {
        const response = await sendRequest("projects");
        return response.data;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    getObjData(dataObj, idx) {
      this.pinIdx = idx;
      this.objectData = dataObj;
    },
    closeModal() {
      this.objectData = null;
    },
  },
  watch: {
    objectData() {
      if (this.objectData) {
        document.documentElement.style.overflow = "hidden";
        return;
      }
      document.documentElement.style.overflow = "visible";
    },
  },
};
</script>
<style>
#map > ymaps {
  width: 100%;
}

.object-map .ymaps-2-1-79-controls__control {
  position: absolute !important;
  right: 20px !important;
  top: 250px !important;
  bottom: auto !important;
  left: auto !important;
}

.ymaps-2-1-79-ground-pane {
  filter: grayscale(1);
  -ms-filter: grayscale(1);
  -webkit-filter: grayscale(1);
  -moz-filter: grayscale(1);
  -o-filter: grayscale(1);
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
