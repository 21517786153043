<template>
  <div class="container sm:px-0">
    <div class="sm:flex items-center justify-between">
      <h3 class="sm:text-2xl text-24 sm:mb-0 mb-12ц">Другие статьи</h3>
      <router-link to="/posts" class="border-[1px] py-[12px] px-8 border-black inline-block mt-8" href="">
        Все статьи
      </router-link>
    </div>
    <div class="md:grid grid-cols-2 gap-20 mt-10">
      <post-item v-for="post in randomPosts" :key="post.slug" :post="post" />
    </div>
  </div>
</template>
<script>
import PostItem from "@/components/PostsPage/PostItem";

export default {
  components: {
    PostItem,
  },
  props: {
    randomPosts: {
      type: Object,
    },
  },
};
</script>
