<template>
  <div class="grayscale modalMaps" :id="slug"></div>
</template>
<script>
/* eslint-disable */
export default {
  props: {
    coords: {
      type: Array,
      required: false,
    },
    slug: {
      type: String,
    }
  },
  mounted() {
    ymaps.ready(this.initMap);
  },
  methods: {
    initMap() {
      // Создаем карту.
      const myMap = new ymaps.Map(document.getElementById(this.slug), {
        center: [...this.coords[Math.ceil(this.coords.length / 2)]],
        zoom: 9,
        controls: [],
      }, {
        suppressMapOpenBlock: true
      });

      const ZoomLayout = ymaps.templateLayoutFactory.createClass("<div>" +
          "<div class='zoom-in btn bg-white py-[17px] px-[17px] border-black border-[1px] cursor-pointer'><img src='./img/map-plus.svg'></div>" +
          "<div class='zoom-out btn bg-white py-[17px] px-[17px] border-black border-[1px] -mt-[1px] cursor-pointer'><img src='./img/map-minus.svg'></div>" +
          "</div>", {

          build: function () {
            // Calling the "build" parent method.
            ZoomLayout.superclass.build.call(this);

            /**
             * Binding handler functions to the context and storing references
             * to them in order to unsubscribe from the event later.
             */
            this.zoomInCallback = ymaps.util.bind(this.zoomIn, this);
            this.zoomOutCallback = ymaps.util.bind(this.zoomOut, this);

            // Beginning to listen for clicks on the layout buttons.
            document.querySelectorAll('.zoom-in').forEach((button) => {
              button.addEventListener('click', this.zoomInCallback);
            })
            document.querySelectorAll('.zoom-out').forEach((button) => {
              button.addEventListener('click', this.zoomOutCallback);
            })
          },

          clear: function () {
            // Removing click handlers.
            document.querySelector('.zoom-in1')
              .removeEventListener('click', this.zoomInCallback);
            document.querySelector('.zoom-out1')
              .removeEventListener('click', this.zoomOutCallback);

            // Calling the "clear" parent method.
            ZoomLayout.superclass.clear.call(this);
          },

          zoomIn: function () {
            var map = this.getData()
              .control
              .getMap();
            map.setZoom(map.getZoom() + 1, { checkZoomRange: true });
          },

          zoomOut: function () {
            var map = this.getData()
              .control
              .getMap();
            map.setZoom(map.getZoom() - 1, { checkZoomRange: true });
          }
        }),
        zoomControl = new ymaps.control.ZoomControl({ options: { layout: ZoomLayout } }, {});
      myMap.controls.add(zoomControl);


      myMap.behaviors.disable('scrollZoom');
      myMap.behaviors.disable('drag');


      // Создаем ломаную, используя класс GeoObject.
      var myGeoObject = new ymaps.GeoObject({
        // Описываем геометрию геообъекта.
        geometry: {
          // Тип геометрии - "Ломаная линия".
          type: "LineString",
          // Указываем координаты вершин ломаной.
          coordinates: this.coords
        },
      }, {
        // Цвет линии.
        strokeColor: "#000000",
        // Ширина линии.
        strokeWidth: 5
      });

      // Создаем ломаную с помощью вспомогательного класса Polyline.
      var myPolyline = new ymaps.Polyline(this.coords, {}, {
        // Задаем опции геообъекта.
        // Отключаем кнопку закрытия балуна.
        balloonCloseButton: false,
        // Цвет линии.
        strokeColor: "#000000",
        // Ширина линии.
        strokeWidth: 4,
        // Коэффициент прозрачности.
      });
      // Добавляем линии на карту.
      myMap.geoObjects
        .add(myGeoObject)
        .add(myPolyline);

      var myPlacemark = new ymaps.Placemark(this.coords[0], {}, {
        // Опции.
        // Необходимо указать данный тип макета.
        iconLayout: 'default#image',
        // Своё изображение иконки метки.
        iconImageHref: './img/extremePoint.svg',
        // Размеры метки.
        iconImageSize: [20, 20],
        // Смещение левого верхнего угла иконки относительно
        // её "ножки" (точки привязки).
        iconImageOffset: [-10, -10]
      });

      var myPlacemark2 = new ymaps.Placemark(this.coords[this.coords.length - 1], {}, {
        // Опции.
        // Необходимо указать данный тип макета.
        iconLayout: 'default#image',
        // Своё изображение иконки метки.
        iconImageHref: './img/extremePoint.svg',
        // Размеры метки.
        iconImageSize: [20, 20],
        // Смещение левого верхнего угла иконки относительно
        // её "ножки" (точки привязки).
        iconImageOffset: [-10, -10]
      });

      myMap.geoObjects.add(myGeoObject)
        .add(myPlacemark)
        .add(myPlacemark2);
    }
  }
};
</script>
