<template>
  <div class="w-full relative" id="mainMap" style="height: 600px"></div>
</template>
<script>
import sendRequest from "@/api";
/* eslint-disable */
export default {
  props: {
    coordinates: {
      type: Array,
      required: false,
    },
    projectSlugs: {
      type: Array,
    }
  },
  created() {

    const sendObjectData = (data, idx) => {
      this.clickOnObj(data, idx);
    };
    setTimeout(() => {
      ymaps.ready(() => {
        var MyIconContentLayout = ymaps.templateLayoutFactory.createClass(
          '<div id="cluster" class="flex cluster justify-center items-center bg-black rounded-full w-[54px] h-[54px] border-[3px] border-white text-white relative z-50">{{ properties.geoObjects.length }}</div>'
        );
        var myMap = new ymaps.Map('mainMap', {
            center: [55.751574, 37.573856],
            zoom: 9,
            controls: [],
          }, {
            suppressMapOpenBlock: true
          }),
          clusterer = new ymaps.Clusterer({
            hasBalloon: false,
            clusterZIndexHover: 40,
            clusterIconColor: '#000000',
            clusterCursor: 'pointer',
            clusterDisableClickZoom: false,
            clusterIconLayout: MyIconContentLayout,
            clusterIconShape: {
              type: 'Circle',
              coordinates: [30, 30],
              radius: 30
            }
          });
        // Creating a custom layout for the zoom slider.
        const ZoomLayout = ymaps.templateLayoutFactory.createClass("<div>" +
            "<div id='zoom-in' class='btn bg-white py-[15px] px-[15px] border-black border-[1px] cursor-pointer'><img src='./img/map-plus.svg'></div>" +
            "<div id='zoom-out' class='btn bg-white py-[15px] px-[15px] border-black border-[1px] -mt-[1px] cursor-pointer'><img src='./img/map-minus.svg'></div>" +
            "</div>", {

            build: function () {
              // Calling the "build" parent method.
              ZoomLayout.superclass.build.call(this);

              /**
               * Binding handler functions to the context and storing references
               * to them in order to unsubscribe from the event later.
               */
              this.zoomInCallback = ymaps.util.bind(this.zoomIn, this);
              this.zoomOutCallback = ymaps.util.bind(this.zoomOut, this);

              // Beginning to listen for clicks on the layout buttons.
              document.querySelector('#zoom-in')
                .addEventListener('click', this.zoomInCallback);
              document.querySelector('#zoom-out')
                .addEventListener('click', this.zoomOutCallback);
            },

            clear: function () {
              // Removing click handlers.
              document.querySelector('#zoom-in')
                .removeEventListener('click', this.zoomInCallback);
              document.querySelector('#zoom-out')
                .removeEventListener('click', this.zoomOutCallback);

              // Calling the "clear" parent method.
              ZoomLayout.superclass.clear.call(this);
            },

            zoomIn: function () {
              var map = this.getData()
                .control
                .getMap();
              map.setZoom(map.getZoom() + 1, { checkZoomRange: true });
            },

            zoomOut: function () {
              var map = this.getData()
                .control
                .getMap();
              map.setZoom(map.getZoom() - 1, { checkZoomRange: true });
            }
          }),
          zoomControl = new ymaps.control.ZoomControl({ options: { layout: ZoomLayout } }, {
            position: {
              right: 0,
              bottom: "auto",
            }
          });
        myMap.controls.add(zoomControl);

        // Кластеры;
        /**
         * Кластеризатор расширяет коллекцию, что позволяет использовать один обработчик
         * для обработки событий всех геообъектов.
         * Будем менять цвет иконок и кластеров при наведении.
         */
        clusterer.events
          // Можно слушать сразу несколько событий, указывая их имена в массиве.
          .add(['mouseenter', 'mouseleave', 'click'], function (e) {
            var target = e.get('target'),
              type = e.get('type');
            if (typeof target.getGeoObjects != 'undefined') {
              // Событие произошло на кластере.
              if (type == 'mouseenter') {
                var clustererPlacemark = e.get('target');
                var overlay = clustererPlacemark.getOverlaySync();
                var layout = overlay.getLayoutSync();
                var element = layout.getParentElement();
                console.log(element)
                const cluster = element.querySelector('#cluster');
                cluster.classList.add('bg-[#0053B3]');
              }
              if (type == 'mouseleave') {
                var clustererPlacemark = e.get('target');
                var overlay = clustererPlacemark.getOverlaySync();
                var layout = overlay.getLayoutSync();
                var element = layout.getParentElement();
                console.log(element)
                const cluster = element.querySelector('#cluster');
                cluster.classList.remove('bg-[#0053B3]');
              }
            } else {
              // Событие произошло на геообъекте.
              if (type == 'mouseenter') {
                target.options.set('iconImageHref', './img/hover-pin.svg');
              } else {
                target.options.set('iconImageHref', './img/pin.svg');
              }
            }
            if (type === 'click') {
              const response = sendRequest(`projects/${target.properties._data.slug}`);
              response.then((data) => {
                sendObjectData(data.data, target.properties._data.idx);
              });
            }
          });

        var getPointData = function (slug, idx) {
            return {
              slug,
              idx
            };
          },
          getPointOptions = function () {
            return {
              iconLayout: 'default#image',
              // Своё изображение иконки метки.
              iconImageHref: './img/pin.svg',
              // Размеры метки.
              iconImageSize: [30, 42],
              // Смещение левого верхнего угла иконки относительно
              // её "ножки" (точки привязки).
              iconImageOffset: [-5, -38],
              iconColor: '#000000',
            };
          },
          points = this.coordinates,
          geoObjects = [];

        for (var i = 0, len = points.length; i < len; i++) {
          geoObjects[i] = new ymaps.Placemark(points[i], getPointData(this.projectSlugs[i], i), getPointOptions());
        }

        clusterer.add(geoObjects);
        myMap.geoObjects.add(clusterer);
        myMap.setBounds(clusterer.getBounds(), {
          checkZoomRange: true,
        });
      });
    }, 1000);
  },
  methods: {
    clickOnObj(data, idx) {
      this.$emit('click-on-pin', data, idx);
    }
  },
};
</script>
<style>
#cluster {
 z-index: 9999;
}
#cluster:hover {
  background: red;
}
</style>
