<template>
    <div class="bg-black absolute py-5 top-0 px-8 z-40 text-white w-full" v-if="isShow">
      <div class="flex justify-between items-center pb-5 border-gray border-b-[1px]">
        <router-link class="mr-8 flex items-center" to="/">
          <img class="mr-5" src="@/assets/img/header/header-logo.svg" alt="">
          <p>Битум Рус</p>
        </router-link>
        <div class="cursor-pointer">
          <img
            @click="this.$emit('close-mobile-menu')"
            class="close-mobile-menu ml-auto"
            src="@/assets/img/header/mobile-menu-close.svg"
            alt="">
        </div>
      </div>
      <div class="py-4 border-gray border-b-[1px]">
        <router-link class="mr-8 text-base" to="/#objects">Наши объекты</router-link>
      </div>
      <div class="py-4 border-gray border-b-[1px]">
        <router-link class="text-base" to="/posts">Блог</router-link>
      </div>
      <div class="border-white border-b-[2px] pb-2 mt-8">
        <a class="text-24" href="">+7 (495) 791-60-40</a>
      </div>
      <div class="mt-8">
        <a class="text-base" href="">info@gbr.msk.ru</a>
      </div>
    </div>
</template>
<script>
export default {
  props: {
    isShow: {
      type: Boolean,
    },
  },
  methods: {
    closeMobileMenu(e) {
      if (e.target.classList.contains('close-mobile-menu')) {
        this.$emit('close-mobile-menu');
      }
    },
  },
  watch: {
    '$route.path': {
      handler() {
        this.$emit('close-mobile-menu');
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
