<template>
  <div v-if="mainDescription" class="sm:mb-32 mb-14">
    <div class="container">
      <div class="grid lg:grid-cols-2 grid-cols-1 gap-x-20">
        <div>
          <p class="text-sm sm:text-base">
            {{mainDescription.body}}
          </p>
          <div class="mt-14 border-gray border-l-[1px] px-4 sm:px-8 text-sm sm:text-base">
            <p>{{mainDescription.annotation}}</p>
          </div>
        </div>
        <div class="lg:order-1 order-first mb-10 lg:mb-0">
            <img
              src="@/assets/img/main-description/descr-circles.svg"
              alt="">
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    mainDescription: {
      type: Object,
    },
  },
};
</script>
