<template>
  <div>
      <the-main title="Блог">
        <template v-slot:img-circle>
          <img
            class="absolute 2xl:-right-[80px] right-0 bottom-0 hidden sm:block z-20"
            src="../assets/img/main/main-circle-postspage.png"
            alt="">
        </template>
        <template v-slot:mobile-img-circle>
          <img
            class="absolute 2xl:-right-[80px] right-0 bottom-0 sm:hidden block z-20"
            src="../assets/img/main/mobile-circle-postspage.png"
            alt="">
        </template>
      </the-main>
      <header-posts :headerPost="headerPost" />
      <posts-list @get-page-slug="getPageSlug" :page="page" @get-page-num="getPageNumber" :posts="posts" :posts-data="postsData"/>
  </div>
</template>
<script>
import sendRequest from "@/api";
import TheMain from "../components/UI/TheMain";
import HeaderPosts from "../components/PostsPage/HeaderPosts";
import PostsList from "../components/PostsPage/PostsList";

export default {
  name: "PostsView",
  components: {
    PostsList,
    HeaderPosts,
    TheMain,
  },
  data() {
    return {
      postsData: null,
      posts: null,
      page: 1,
      queryTag: "",
      headerPost: null,
    };
  },
  async mounted() {
    const response = await this.getHeaderPost();
    if (response) {
      if (this.$route.query.tags) {
        const paramString = window.location.search.split('?')[1];
        const queryString = new URLSearchParams(paramString);
        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of queryString.entries()) {
          if (key === 'tags') {
            this.queryTag = value;
          }
        }
      }
      if (this.$route.query.page && this.$route.query.page !== '1') {
        this.page = +this.$route.query.page;
        return;
      }
      await this.getPosts();
    }
  },
  methods: {
    getPageNumber(pageNum) {
      this.page = +pageNum;
    },
    getPageSlug(slug) {
      const url = new URL(window.location);
      if (url.searchParams.get('tags') === slug) {
        this.queryTag = "";
        return;
      }
      this.page = 1;
      this.queryTag = slug;
    },
    async getHeaderPost() {
      try {
        const response = await sendRequest(`blog/posts`);
        const [firstPost] = response.data;
        this.headerPost = firstPost;
        return response;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    async getPosts() {
      try {
        const response = await sendRequest(`blog/posts?exclude=${this.headerPost?.slug}`);
        this.posts = response.data;
        this.postsData = response;
        return response;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    async getQueryPosts(pageParams) {
      try {
        const response = await sendRequest(`blog/posts${pageParams}&exclude=${this.headerPost?.slug}`);
        this.posts = response.data;
        this.postsData = response;
        return response;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
  },
  watch: {
    page(value) {
      const url = new URL(window.location);
      url.searchParams.set('page', value);
      this.$router.push({}, '', url);
      this.getQueryPosts(url.search);
      this.$router.push({
        query: {
          ...this.$route.query,
          page: value,
        },
      });
    },
    queryTag(value) {
      setTimeout(() => {
        const url = new URL(window.location);
        url.searchParams.set('tags', value);
        this.$router.push({}, '', url);
        this.$router.push({
          query: {
            ...this.$route.query,
            tags: value,
          },
        });
        this.getQueryPosts(url.search);
      }, 0);
    },
  },
};
</script>
